import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from "../containers/projects/banner";
import Project from "../containers/projects/project";

// eslint-disable-next-line import/no-anonymous-default-export
const EventPage = () => (
  <Layout>
    <SEO title="Event" />
    <Banner />
    <Project />
  </Layout>
);
export default EventPage;
